/** @flow */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import map from 'lodash/map'
import { bindActionCreators } from 'redux'
import Badge from '@material-ui/core/Badge'
import { connect, useDispatch, useSelector } from 'react-redux'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { showPanel } from 'app/components/OverPage/OverPage.jsx'
import api from 'app/core/api/api.js'
import history from 'app/main/routerHistory'
import type { User } from 'app/core/types'
import NotificationsRightPanel from 'app/containers/Notifications'
import { maxNotifsToDisplay } from 'app/containers/Notifications/NotificationsRightPanel.jsx'
import resources from 'app/store/resources'
import { Permission, permission } from 'app/containers/Permissions'
import socket from 'app/core/api/socket'
import { getResources } from 'app/store/selectors'
import * as notifsCountActions from 'app/store/actions/notifsCount'
import { durationStr } from 'app/components/Duration/Duration.tsx'
import { IconButton } from 'app/components/Form/Button/IconButton.jsx'
import classes from './Header.module.scss'
import { filter, forEach, groupBy, uniqBy } from 'lodash'
import { fetchTodayMyActivities } from '../../../store/reducers/reduxActivities'

type Props = {|
  user: User,
  unreadNotifs?: number,
  notifsCountActions: Object,
  pathname: string,
|}

const itemTypes = { TIMER: 'Timer', NOTIFICATIONS: 'Notifications' }

function IconsNav(props: Props): React$Node {
  const { user, unreadNotifs, notifsCountActions, pathname } = props

  const dispatch = useDispatch()

  const loggedUserTodayActivities = useSelector((state) => state.reduxActivities.loggedUserTodayActivities)
  const { myActivitiesLoading, fetchLoading } = useSelector((state) => state.reduxActivities)

  const [clickedItem, setClickedItem] = useState(null)
  const [rightPanelIsOpen, setRightPanelIsOpen] = useState(false)

  const socketRoom = useRef()
  const subCreate = useRef()

  useEffect(() => {
    if (permission(['notifications___read'])) {
      resources.notifications.fetchUserNotifications(
        {
          id: user.asset,
        },
        'fetchNotificationsForRightPanel',
        {
          params: {
            queries: {
              page_size: maxNotifsToDisplay,
            },
          },
        },
      )
    }
  }, [user])

  useEffect(() => {
    socketRoom.current = socket.subscribe(`user_${user.asset}`)
    subCreate.current = socketRoom.current.on('new_notification', () => {
      notifsCountActions.setNotifsCount(unreadNotifs ? unreadNotifs + 1 : 1)
    })
    return () => {
      socketRoom.current?.remove()
    }
  }, [user, unreadNotifs, notifsCountActions])

  const todayDate = useMemo(() => moment().format('YYYY-MM-DD'), [])

  useEffect(() => {
    if (permission(['my hours___read', 'my hours_my allotment hours__read'], 'or')) {
      resources.activities.fetchAllActivities({ startDate: todayDate, endDate: todayDate })
    }
  }, [])

  useEffect(() => {
    if (permission(['my hours___read', 'my hours_my allotment hours__read'], 'or')) {
      dispatch(fetchTodayMyActivities({ date__range: `${todayDate},${todayDate}` }))
    }
  }, [])

  const { altDuration, tskDuration } = useSelector((state) => {
    const actvts = map(
      getResources(
        state,
        'activities',
        (a) => Boolean(a.user === user.asset && a.date === todayDate && !a.activityType),
        ['taskInst', 'taskInst.assetInst'],
      ),
    )

    // temp variable to get logged user activities from activities "old" reducer and reduxActivities "new" reducer.
    // to delete when my hours table will use TableRedux component
    const allActivitiesFromReducers = uniqBy([...actvts, ...loggedUserTodayActivities], 'id')
    const allActivities = [...actvts, ...loggedUserTodayActivities]
    const allActivitiesById = groupBy(allActivities, 'id')
    const updatedActivitiesByGroup = map(allActivitiesById, (el) => {
      const currentTime = Date.now()
      return el.reduce((closest, date) => {
        return Math.abs(currentTime - (date?.updatedAt ?? 0)) < Math.abs(currentTime - (closest?.updatedAt ?? 0))
          ? date
          : closest
      })
    })

    return {
      altDuration: allActivitiesFromReducers
        .filter((a) => a.taskInst?.taskTypeInst?.name === 'alt')
        .reduce((acc, a) => acc + a.duration, 0),
      tskDuration: updatedActivitiesByGroup
        .filter((a) => !a.task || (a.taskInst?.taskTypeInst?.name && a.taskInst.taskTypeInst.name !== 'alt'))
        .reduce((acc, a) => acc + a.duration, 0),
    }
  })

  function onRequestClose() {
    setClickedItem(null)
    setRightPanelIsOpen(false)
  }

  function onClickIconNotifications() {
    if ((unreadNotifs || 0) > 0) {
      const promise = resources.notifications.fetchUserNotifications(
        {
          id: user.asset,
        },
        'fetchNotificationsForRightPanel',
        {
          params: {
            queries: {
              page_size: maxNotifsToDisplay,
            },
          },
        },
      )
      if (promise) {
        promise.then((res) => {
          if (res.resources && res.resources.length) {
            api.assets.update({
              id: user.asset,
              attributes: { ovm_lastSeenNotification: res.resources[0].id },
            })
          }
          notifsCountActions.setNotifsCount(0)
        })
      }
    }
    setRightPanelIsOpen(true)
    setClickedItem(itemTypes.NOTIFICATIONS)
  }

  function renderRightPanelItem() {
    switch (clickedItem) {
      case itemTypes.NOTIFICATIONS:
        return <NotificationsRightPanel style={{ padding: 0 }} onCloseNotifications={onRequestClose} />
      default:
        return null
    }
  }

  function renderRightPanel() {
    if (!rightPanelIsOpen && !clickedItem) return null

    showPanel('notifications', renderRightPanelItem(), onRequestClose, {
      defaultWidth: 'auto',
    })
    return null
  }

  function isActive(uri) {
    return pathname.startsWith(uri)
  }

  return (
    <>
      <IconButton
        data-testid="nav-top-trombinoscope"
        to="/organization-chart"
        className={classes.button}
        style={{
          backgroundColor: isActive('/organization-chart') ? '#3DC3D2' : undefined,
          color: isActive('/organization-chart') ? '#ffffff' : undefined,
        }}
        color={isActive('/organization-chart') ? 'primary' : 'default'}
      >
        <FontIcon icon="fas-users" className={classes.buttonIcon} />
      </IconButton>
      <Permission actions={['my hours___read']}>
        <IconButton
          data-testid="nav-top-myhours"
          to="/my-hours"
          className={classes.button}
          style={{
            backgroundColor: isActive('/my-hours') ? '#3DC3D2' : undefined,
            color: isActive('/my-hours') ? '#ffffff' : undefined,
          }}
          color={isActive('/my-hours') ? 'primary' : 'default'}
        >
          <div className="flex column center alignCenter fullWidth fullHeight">
            <FontIcon icon="fas-hourglass-half" className="fontSize16" />
            <div
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              {durationStr(tskDuration, undefined, { days: true }, '[H]h[M]')}
            </div>
          </div>
        </IconButton>
      </Permission>
      <Permission actions={['my hours_my allotment hours__read']}>
        <IconButton
          data-testid="nav-top-myhours-allotment"
          to="/my-allotment-hours"
          className={classes.button}
          style={{
            backgroundColor: isActive('/my-allotment-hours') ? '#3DC3D2' : undefined,
            color: isActive('/my-allotment-hours') ? '#ffffff' : undefined,
          }}
          color={isActive('/my-allotment-hours') ? 'primary' : 'default'}
        >
          <div className="flex column center alignCenter fullWidth fullHeight">
            <FontIcon icon="fas-clock" className="fontSize16" />
            <div
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              {durationStr(altDuration, undefined, { days: true }, '[H]h[M]')}
            </div>
          </div>
        </IconButton>
      </Permission>
      <Permission actions={['notifications___read']}>
        <IconButton data-testid="nav-top-notifications" onClick={onClickIconNotifications} className={classes.button}>
          <>
            <FontIcon icon="fas-bell" className={classes.buttonIcon} />
            <Badge
              overlap="circular"
              badgeContent={unreadNotifs}
              max={99}
              color="primary"
              style={{ top: 10, right: 5, position: 'absolute' }}
            >
              <div />
            </Badge>
          </>
        </IconButton>
      </Permission>

      {renderRightPanel()}
    </>
  )
}

export default (connect<any, any, any, any, any, any>(
  (state) => {
    const request = state.notifications && state.notifications.requests.fetchNotificationsForRightPanel

    return {
      pathname: history.location.pathname,
      user: state.user,
      unreadNotifsFromRequest: request && request.unread,
      unreadNotifs: state.notifsCount,
    }
  },
  (dispatch) => ({
    notifsCountActions: bindActionCreators(notifsCountActions, dispatch),
  }),
)(IconsNav): Class<*> & ((props: Props) => React$Node))
