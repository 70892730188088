/** @flow */
import React, { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import type { Asset, ID, Pipe, Activity } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { MUIModal } from 'app/components/Modal'
import { getResources } from 'app/store/selectors'
import resources from '../../../store/resources'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserTasks } from '../../../store/reducers/tasks'
import { userPrefLocalResource } from '../../../core/utils/localUserPrefs'
import { tableUserPrefsDataSelector, tableUserPrefsSortBySelector } from '../../../store/selectors/tableUserPrefs'
import { formattedTasksWithTakesSelector, tasksSelector } from '../../../store/selectors/tasks'
import { TableTasksRedux } from '../../Project/Tasks/TableTasksRedux'
import { map } from 'lodash'
import { Filters as DefaultFilters } from 'app/pages/Project/Tasks/Filters.js'
import { useLocation } from 'react-router-dom'
import { filtersToQueries, filtersToURL, urlToFilters } from '../../../components/Table/hooks/useFiltersUtils'
import routerHistory from 'app/main/routerHistory.js'
import { tableFiltersDataSelector } from '../../../store/selectors/tablesFilters'
import { getURLParamsKey } from '../../../components/Table/utils'
import { setInputFilters } from '../../../store/reducers/tablesFilters'
import { useProgress } from '../../../hooks/useProgress'
import useWebsocket from 'app/core/api/useWebSocket'
import { setTableConfig } from '../../../store/reducers/tablesPrefs'

type Props = {|
  user: Asset,
  date: Date,
  onAddActivities?: (activities: Array<Activity>) => void,
|}

function ModalAddActivitiesForUserView(props: Props): React$Node {
  const { date, user, onAddActivities, projectId, ...rest } = props
  useWebsocket()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { initProgresses, updateProgress, progress } = useProgress()

  const tableId = 'AddActivitiesTableTasks'
  const table = userPrefLocalResource.getData('tables')?.[tableId]
  const { count, loading } = useSelector(tasksSelector)
  const data = useSelector(formattedTasksWithTakesSelector)
  const tableUserPrefs = useSelector(tableUserPrefsDataSelector(tableId))
  const filtersData = useSelector(tableFiltersDataSelector)
  const sortBy = useSelector(tableUserPrefsSortBySelector(tableId))
  const isLoadingSorting = useSelector((state) => state.tablesPrefsLoader.loading)

  const projects = getResources(undefined, 'assets', { assetType: 'pj' })
  const progressionStatus = useSelector((state) => state.progressionStatus.resources)
  const assetsAttributes = map(getResources(undefined, 'attributes', projectId ? { project: projectId } : undefined))
  // const steps = () => {
  //   const stepProjects = getResources(undefined, 'stepProjects', projectId ? { project: projectId } : undefined, [
  //     'stepInst',
  //   ])
  //   if (projectId) return getSubResources(stepProjects, 'stepInst')
  //   return getResources(undefined, 'steps')
  // }
  const [requestsLoading, setRequestsLoading] = useState(true)
  const [filtersloading, setFiltersLoading] = useState(true)

  const getOrderingKeys = ({ sortingKeys }) => {
    const sortingsStrings = map(sortingKeys, (sort) => {
      return sort.key
    })
    return sortingsStrings.join()
  }

  const filters = useMemo(() => {
    if (!requestsLoading) {
      const filt = new DefaultFilters({
        episodes: [],
        categories: [],
        progressionStatus,
        assetsAttributes,
        projects,
      }).getFilters()
      return filt
    }
  }, [requestsLoading])

  useEffect(() => {
    return () => {
      setRequestsLoading(true)
      setFiltersLoading(true)
      dispatch(setTableConfig({ tableId, data: { page: 1 } }))
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!search?.includes('filters') && filtersloading) {
        setFiltersLoading(false)
      }
      if (search.includes('filters') && filters.length && filtersloading) {
        const params = new URLSearchParams(search)
        const urlCurrentFilters = params.get(getURLParamsKey('filters', tableId))
        const urlFilters = await urlToFilters(urlCurrentFilters, filters, undefined, undefined, false)
        dispatch(setInputFilters(urlFilters))
        setFiltersLoading(false)
      }
    })()
  }, [filters])

  useEffect(() => {
    ;(async () => {
      initProgresses()
      const params = (progressKey) => ({
        params: {
          queries: { page_size: 1000 },
          headers: { [window.OVM_PROJECT_HEADER]: projectId || '' },
          getHttpProgress: (p) => updateProgress(progressKey, p),
        },
      })
      await Promise.all([
        resources.progressionStatus.fetchAll(params('progressionStatus')),
        resources.assetsCustomAttributValues.fetch('category', {
          params: { getHttpProgress: (p) => updateProgress('assetsCustomAttributValues', p) },
        }),
        resources.steps.fetchAll(params('steps')),
        resources.attributes.fetchAll(params('attributes')),
        resources.assets.fetchAll({
          params: {
            queries: { page_size: 1000, assetType: 'pj' },
            getHttpProgress: (p) => updateProgress('assets-pj', p),
          },
        }),
      ])
      setRequestsLoading(false)
      initProgresses()
    })()
  }, [])

  useEffect(() => {
    if (!requestsLoading && !isLoadingSorting && !filtersloading) {
      const { queries: filtQueries } = filtersToQueries(filtersData)
      const url = filtersToURL(filtersData)
      routerHistory.updateQuery({ [getURLParamsKey('filters', tableId)]: url || null })
      const queries = {
        page_size: tableUserPrefs?.pageSize || table?.pageSize || 50,
        page: tableUserPrefs?.page || 1,
        ordering: getOrderingKeys(sortBy || {}) || '',
        ...filtQueries,
      }
      dispatch(fetchUserTasks({ userId: user.id, queries }))
    }
  }, [
    tableUserPrefs?.pageSize,
    tableUserPrefs?.page,
    requestsLoading,
    filtersloading,
    filtersData,
    sortBy,
    isLoadingSorting,
  ])

  return (
    <MUIModal
      title={`Add activities for ${user.name} ${date ? ` - ${moment(date).format('dddd DD MMM YYYY')}` : ''}`}
      width={1600}
      height={1000}
      resizable={true}
      draggable={true}
      {...rest}
    >
      <TableTasksRedux
        tableId={tableId}
        enableAddTime={true}
        addTimeToSpecifiedDate={date}
        customUser={user}
        onAddActivities={onAddActivities}
        data={data}
        loading={loading}
        count={count}
        projects={projects}
        filters={filters}
        assetsAttributes={assetsAttributes}
        requestsLoading={requestsLoading}
        progressionStatus={progressionStatus}
      />
    </MUIModal>
  )
}

const pipeInst: Pipe<{ userId: ID }, typeof ModalAddActivitiesForUserView> = pipe()

export const ModalAddActivitiesForUser: React$AbstractComponent<any, any> = pipeInst
  .connect((state, props) => {
    return {
      user: getResources(state, 'assets', props.userId),
    }
  })
  .request((props) => {
    const { userId } = props
    return resources.assets.fetchOne(userId)
  })
  .renderLoader(() => null)
  .render(({ userId, ...props }) => <ModalAddActivitiesForUserView {...props} />)
