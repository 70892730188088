import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { forEach, reduce, uniq } from 'lodash';
import { router } from 'app/containers/Assets/AssetDetail/router';
import { CellText, CellLink, CellThumbnail, CellProgression, CellDuration, CellFlags, CellRichText, CellAssetsSelect, CellStatus, CellMedias, CellPriority, } from 'app/components/Table/Cells';
import { CellStatsDefault, CellStatsStatus, CellStatsAssignedUsers, } from 'app/components/Table/Cells/StatsCells/index.js';
import { accessorTakeMedias, saveTakeMedias } from 'app/components/Table/Cells/CellMedias/utils';
import { DurationRead } from 'app/components/Duration/Duration';
import { progressionStatusFilter } from 'app/core/utils/optionsProgressionStatus';
import { openModal } from 'app/components/Modal';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { permission } from 'app/containers/Permissions';
import vars from 'app/styles/vars.js';
import { tableId as TableMyTaskId } from 'app/pages/MyTasks/MyTasks.jsx';
import { Popover } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ModalEditTake from 'app/containers/Take/ModalEditTake/ModalEditTake';
import store from 'app/store/index';
import { router as routerTaskDetail } from 'app/containers/Task/router';
import { ToggleButton } from 'app/components/Form/index.js';
import { ModalEditTask } from 'app/containers/Task/ModalEditTask.jsx';
import history from 'app/main/routerHistory.js';
import { MyHoursCalendar } from 'app/containers/MyHoursCalendar/MyHoursCalendar.jsx';
import reduxAttributesColumns from 'app/components/AttributesColumns/reduxAttributesColumns.jsx';
import { AddTimeModal } from './AddTimeModal.jsx';
import { getLastTakeFromTask } from '../ProductionManagement/FollowUp/utils';
import { checkTaskPerms, checkTakePerms, checkMyTasksPerms } from './checkPerms';
import { TableRedux } from 'app/components/Table/TableRedux';
import { updateTask } from 'app/store/reducers/tasks';
import { useDispatch } from 'react-redux';
import { updateTake } from 'app/store/reducers/takes';
import { createTakeFlags, deleteTakeFlags } from 'app/store/reducers/takesFlags';
export function TableTasksRedux(props) {
    const { tableId, enableAddTime = false, addTimeToSpecifiedDate, enableStats, swapTableType, resources: propsResources, projectId, customUser, fromAsset, onAddActivities, data = [], count, statsOpen, setStatsOpen, stats, statsIsLoading, requestsLoading, assetsAttributes, progressionStatus, steps, projects, filters, } = props;
    const dispatch = useDispatch();
    const canAddActivityForOtherUsers = useMemo(() => permission(['projet_tasks__add activity for other users']), []);
    const canAddActivityOnAllTasks = useMemo(() => permission(['projet_tasks__can add activity on all tasks']), []);
    const getUserId = useCallback((row) => {
        var _a;
        if (customUser)
            return customUser.id;
        let addTimeToUser;
        if (canAddActivityForOtherUsers && row) {
            addTimeToUser = (_a = row.original.taskInst) === null || _a === void 0 ? void 0 : _a.assignedUser;
        }
        else if (tableId === 'MyTaskTableTask' || canAddActivityOnAllTasks) {
            addTimeToUser = store.getState().user.asset;
        }
        return addTimeToUser || store.getState().user.asset;
    }, [customUser, canAddActivityForOtherUsers, canAddActivityOnAllTasks]);
    const defaultActions = (row) => [
        { separator: true },
        {
            label: 'Show task details',
            onClick: (e) => routerTaskDetail.goTo('index', { taskId: row.original.taskInst.id }, { rightPanel: true }),
            editAction: false,
        },
        {
            label: 'Create new take',
            onClick: (event) => {
                openModal(_jsx(ModalEditTake, { taskId: row.original.taskInst.id }));
            },
            editAction: true,
        },
    ];
    const [rowSelector, setRowSelector] = useState(false);
    function showRowSelector(value) {
        setRowSelector(typeof value === 'boolean' ? value : !rowSelector);
    }
    function addTimeOnSelectedTasks(event, tableInstance) {
        const instance = tableInstance.getLastestInstance();
        const { state: { rowState }, rowsById, cellsByRowId, updateCells, } = instance;
        const taskIds = [];
        const selectedCells = {};
        forEach(rowState, (state, rowId) => {
            var _a, _b, _c;
            if (state.selected === true) {
                if ((_b = (_a = rowsById[rowId]) === null || _a === void 0 ? void 0 : _a.original) === null || _b === void 0 ? void 0 : _b.task) {
                    taskIds.push(rowsById[rowId].original.task);
                    if ((_c = cellsByRowId === null || cellsByRowId === void 0 ? void 0 : cellsByRowId[rowId]) === null || _c === void 0 ? void 0 : _c[0])
                        selectedCells[cellsByRowId[rowId][0].id] = cellsByRowId[rowId][0];
                }
            }
        });
        return openModal(_jsx(AddTimeModal, { addTimeToSpecifiedDate: addTimeToSpecifiedDate, userId: getUserId(), taskIds: taskIds, onChange: (activities) => {
                showRowSelector(false);
                updateCells(selectedCells);
                if (onAddActivities)
                    onAddActivities(activities);
            } }));
    }
    // TO DO : type ToggleButtons with TsToggleButtonsProps (to create)
    const ToggleButtons = (instance) => {
        const createTask = {
            key: 'createTask',
            icon: 'fas-plus',
            onClick: () => openModal(_jsx(ModalEditTask, { title: "Create a new task", fromAsset: fromAsset, onSuccess: () => {
                    instance.reloadData();
                } })),
            label: 'Add a task',
        };
        const selectTask = {
            key: 'selectTask',
            onClick: () => showRowSelector(),
            selected: rowSelector,
            icon: 'far-check-square',
            label: 'Select tasks',
        };
        const addTime = {
            key: 'addTimeTask',
            onClick: (e) => addTimeOnSelectedTasks(e, instance),
            icon: 'fas-hourglass-half',
            label: 'Add time on selected tasks',
        };
        return [
            ...(permission(['projet_tasks__create (not recommended)', 'projet_tasks__create button'], 'and') && !!projectId
                ? [createTask]
                : []),
            ...(enableAddTime === true ? [[selectTask, ...(rowSelector ? [addTime] : [])]] : []),
        ];
    };
    const ExtendedToolbar = useCallback((instance) => {
        let swapableComponent;
        if (swapTableType) {
            if (typeof swapTableType === 'function')
                swapableComponent = swapTableType(instance);
            else
                swapableComponent = swapTableType;
            swapableComponent = _jsx("div", { style: { margin: '0px 5px' }, children: swapableComponent });
        }
        return (_jsxs("div", { className: "flex row noWrap alignCenter", children: [swapableComponent, tableId === TableMyTaskId ? (_jsx(PopupState, { variant: "popover", popupId: "calendar", children: (popupState) => {
                        const { onClick } = bindTrigger(popupState);
                        return (_jsxs(_Fragment, { children: [_jsxs(ToggleButton, { onClick: onClick, style: { margin: '0px 5px' }, children: [_jsx(FontIcon, { icon: "fas-calendar", className: "marginRight10" }), _jsx("span", { className: "bold", children: "Calendar" })] }, "calendarTask"), _jsx(Popover, Object.assign({}, bindPopover(popupState), { anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }, transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }, children: _jsx(MyHoursCalendar, { selectedWeek: moment(), userId: getUserId(), onSelectWeek: (selectedWeek) => {
                                            history.push(`/my-hours/week/${selectedWeek.format('w-YYYY')}`);
                                        } }) }))] }));
                    } })) : null] }));
    }, [swapTableType]);
    const showAddTime = useMemo(() => enableAddTime || tableId === 'MyTaskTableTask', [enableAddTime]);
    const readOnly = useMemo(() => !permission([
        'projet_tasks_tasks_create',
        'projet_tasks_tasks_update',
        'projet_tasks_takes_create',
        'projet_tasks_takes_update',
        'projet_tasks_takes_delete',
        'my tasks___edit status/notes/validationMedias',
        'my tasks___edit brief',
    ], 'or'), []);
    const projectsByAttributes = useMemo(() => {
        return ((assetsAttributes === null || assetsAttributes === void 0 ? void 0 : assetsAttributes.reduce((acc, attribute) => {
            if (!acc[attribute.name])
                acc[attribute.name] = [attribute.project];
            else
                acc[attribute.name].push(attribute.project);
            return acc;
        }, {})) || {});
    }, [assetsAttributes]);
    const attributesColumns = useMemo(() => reduxAttributesColumns({ attributes: assetsAttributes, readOnly, projectsByAttributes, dispatch }), [assetsAttributes, projectsByAttributes]);
    const columns = useMemo(() => {
        if (requestsLoading)
            return [];
        return [
            {
                Header: 'Assets',
                id: 'assets',
                columns: [
                    CellLink({
                        Header: 'Asset',
                        accessor: 'taskInst.assetInst.name',
                        id: 'assetName',
                        showRemoved: true,
                        width: 150,
                        readOnly,
                        onClick: (row) => router.goTo('index', { assetId: row.original.taskInst.assetInst.id }, { rightPanel: true }),
                        fixed: 'left',
                        fixable: true,
                        sortingKey: 'asset__name',
                        actions: (instance, cell) => checkTaskPerms([...defaultActions(cell.row)]),
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                    }),
                    CellText({
                        hiddenable: true,
                        Header: 'Step',
                        accessor: 'taskInst.stepInst.name',
                        fixable: true,
                        id: 'step',
                        actions: (instance, cell) => checkTaskPerms([...defaultActions(cell.row)]),
                        width: 100,
                        readOnly,
                        sortingKey: 'step__name',
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        Stats: (instance) => (_jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['step__counts'], updateFields: (entry) => {
                                var _a, _b;
                                return ({
                                    Step: ((_b = (_a = steps()) === null || _a === void 0 ? void 0 : _a[entry.step_id]) === null || _b === void 0 ? void 0 : _b.name) || '?',
                                    count: entry.count,
                                });
                            } })),
                    }),
                    CellThumbnail({
                        Header: 'Thumbnail',
                        accessor: (take) => {
                            if (!take || !take.taskInst || !take.taskInst.assetInst)
                                return null;
                            return take.taskInst.assetInst.thumbnailInst;
                        },
                        id: 'thumbnail',
                        fixable: true,
                        hiddenable: true,
                        width: 150,
                        readOnly,
                        actions: (instance, cell) => checkTaskPerms(['edit', 'delete', ...defaultActions(cell.row)]),
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        Stats: (instance) => _jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['thumbnail'] }),
                        save: {
                            resource: 'assets',
                            formatData: (item, value, cell, instance, type) => {
                                if (type === 'delete') {
                                    return {
                                        id: item.taskInst && item.taskInst.assetInst && item.taskInst.assetInst.id,
                                        thumbnail: null,
                                    };
                                }
                                return {
                                    id: item.taskInst && item.taskInst.assetInst && item.taskInst.assetInst.id,
                                    thumbnail: typeof value === 'string' ? value : value === null || value === void 0 ? void 0 : value.id,
                                };
                            },
                        },
                    }),
                ],
            },
            {
                Header: 'Tasks',
                headerColor: vars.colors.blue,
                id: 'tasks',
                columns: [
                    CellText({
                        id: 'taskName',
                        Header: 'Task name',
                        accessor: 'taskInst.name',
                        fixable: true,
                        hiddenable: true,
                        readOnly,
                        noFilterOption: true,
                        sortingKey: 'name',
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        Stats: (instance) => (_jsx(CellStatsDefault, { instance: instance, resource: "tasks", updateFields: (entry) => {
                                var _a, _b;
                                if (typeof entry === 'object') {
                                    if (entry.step_id) {
                                        return {
                                            Step: ((_b = (_a = steps()) === null || _a === void 0 ? void 0 : _a[entry.step_id]) === null || _b === void 0 ? void 0 : _b.name) || '?',
                                            count: entry.count,
                                        };
                                    }
                                    if (entry.asset__parent__name) {
                                        return {
                                            'Parent name': entry.asset__parent__name,
                                            'Asset type': entry.asset__parent__assetType,
                                            count: entry.count,
                                        };
                                    }
                                }
                                return entry;
                            } })),
                        actions: (instance, cell) => checkTaskPerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]),
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({ id: data.task, name: newValue }),
                        },
                    }),
                    CellAssetsSelect({
                        Header: 'Project',
                        assetTypes: ['pj'],
                        placeholder: 'Project...',
                        fixable: true,
                        hiddenable: true,
                        id: 'projectName',
                        sortingKey: 'asset__project__name',
                        width: 200,
                        readOnly,
                        actions: (instance, cell) => checkTaskPerms([...defaultActions(cell.row)]),
                        accessor: (take) => {
                            var _a, _b;
                            const assetProjectId = (_b = (_a = take.taskInst) === null || _a === void 0 ? void 0 : _a.assetInst) === null || _b === void 0 ? void 0 : _b.project;
                            return (assetProjectId && projects[assetProjectId]) || null;
                        },
                        hidden: Boolean(projectId),
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                    }),
                    CellAssetsSelect({
                        Header: 'Asset parent',
                        fixable: true,
                        placeholder: '',
                        hiddenable: true,
                        showIcon: true,
                        sortingKey: 'asset__parent__name',
                        id: 'episode',
                        width: 170,
                        readOnly,
                        actions: (instance, cell) => checkTaskPerms([...defaultActions(cell.row)]),
                        Stats: (instance) => {
                            return (_jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['asset__parent__counts'], updateFields: (entry) => ({
                                    'Parent name': entry.asset__parent__name,
                                    'Asset type': entry.asset__parent__assetType,
                                    count: entry.count,
                                }) }));
                        },
                        accessor: 'taskInst.assetParentInst',
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                    }),
                    CellAssetsSelect({
                        Header: 'Assigned user',
                        id: 'assignedUser',
                        fixable: true,
                        hiddenable: true,
                        sortingKey: 'assignedUser__name',
                        width: 175,
                        readOnly,
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        accessor: (item) => {
                            if (!item)
                                return undefined;
                            const { taskInst } = item;
                            return (taskInst === null || taskInst === void 0 ? void 0 : taskInst.assignedUserInst) || undefined;
                        },
                        adornment: (cell, isRowExpanded, prefs) => {
                            var _a;
                            const { taskInst } = cell.row.original;
                            const suggestedUserName = taskInst && !taskInst.assignedUser && ((_a = taskInst.suggestedUserInst) === null || _a === void 0 ? void 0 : _a.name);
                            return suggestedUserName ? (_jsxs("span", { className: "absolute grey fontSize12", style: { zIndex: 1, top: 0, left: 5 }, children: [_jsx(FontIcon, { icon: "fas-scroll", className: "marginRight5 fontSize10" }), _jsx("span", { style: {
                                            borderRadius: 3,
                                            backgroundColor: '#008AE6',
                                            color: '#fff',
                                            padding: '0px 5px',
                                            marginRight: 5,
                                        }, children: suggestedUserName }), "is suggested"] })) : null;
                        },
                        Stats: (instance) => _jsx(CellStatsAssignedUsers, { instance: instance, columnName: "Assigned user" }),
                        assetTypes: ['us'],
                        placeholder: 'Assign an user',
                        actions: (instance, cell) => {
                            var _a;
                            const { taskInst } = cell.row.original;
                            const shouldHaveSuggestMenu = Boolean(taskInst && !taskInst.assignedUser && taskInst.suggestedUserInst);
                            if (!shouldHaveSuggestMenu) {
                                return checkTaskPerms(['edit', 'delete', 'copy', 'past', ...defaultActions(cell.row)]);
                            }
                            const { state: { selectedCells }, } = instance;
                            const autoAssigneSuggestion = {
                                label: Object.keys(selectedCells || {}).length > 1
                                    ? 'Assign suggestions'
                                    : `Assign ${((_a = taskInst.suggestedUserInst) === null || _a === void 0 ? void 0 : _a.name) || 'suggestion'}`,
                                rightLabel: 'Ctrl + A',
                                editAction: true,
                                onClick: (event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    const { getLastestSelection } = instance.getLastestInstance();
                                    forEach(getLastestSelection().selectedCells, (cell, cellId) => {
                                        var _a, _b;
                                        const { taskInst } = cell.row.original;
                                        const suggestedUserId = taskInst && !taskInst.assignedUser && ((_a = taskInst.suggestedUserInst) === null || _a === void 0 ? void 0 : _a.id);
                                        if (suggestedUserId)
                                            (_b = cell.getCellProps().edition) === null || _b === void 0 ? void 0 : _b.saveTargetOnly({ id: suggestedUserId });
                                    });
                                },
                                hotKeys: ['ctrl+a', 'command+a'],
                            };
                            return [
                                autoAssigneSuggestion,
                                { separator: true },
                                ...checkTaskPerms(['edit', 'delete', 'copy', 'past', ...defaultActions(cell.row)]),
                            ];
                        },
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({ id: data.task, assignedUser: newValue.id }),
                        },
                    }),
                    CellPriority({
                        id: 'priority',
                        Header: 'Priority',
                        fixable: true,
                        hiddenable: true,
                        sortingKey: 'priority',
                        accessor: 'taskInst.priority',
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        actions: (instance, cell) => checkTaskPerms(['edit', 'delete', 'copy', 'past', ...defaultActions(cell.row)]),
                        readOnly,
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({ id: data.task, priority: newValue }),
                        },
                    }),
                    CellText({
                        id: 'startDate',
                        Header: 'Start date',
                        accessor: (item) => {
                            var _a;
                            if (!((_a = item === null || item === void 0 ? void 0 : item.taskInst) === null || _a === void 0 ? void 0 : _a.startDate))
                                return undefined;
                            return moment(item.taskInst.startDate).format('YYYY-MM-DD');
                        },
                        fixable: true,
                        hiddenable: true,
                        sortingKey: 'startDate',
                        width: 150,
                        readOnly,
                        inputProps: {
                            type: 'date',
                        },
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        Stats: (instance) => _jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['startDate'] }),
                        actions: (instance, cell) => checkTaskPerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]),
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({ id: data.task, startDate: moment(newValue, 'YYYY-MM-DD').toISOString() }),
                        },
                    }),
                    CellText({
                        id: 'endDate',
                        Header: 'End date',
                        accessor: (item) => {
                            var _a;
                            if (!((_a = item === null || item === void 0 ? void 0 : item.taskInst) === null || _a === void 0 ? void 0 : _a.endDate))
                                return undefined;
                            return moment(item.taskInst.endDate).format('YYYY-MM-DD');
                        },
                        fixable: true,
                        hiddenable: true,
                        sortingKey: 'endDate',
                        width: 150,
                        readOnly,
                        inputProps: {
                            type: 'date',
                        },
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        Stats: (instance) => _jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['endDate'] }),
                        actions: (instance, cell) => checkTaskPerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]),
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({ id: data.task, endDate: moment(newValue, 'YYYY-MM-DD').toISOString() }),
                        },
                    }),
                    CellText({
                        id: 'earliestStartDate',
                        Header: 'Earliest start date',
                        accessor: (item) => {
                            var _a;
                            if (!((_a = item === null || item === void 0 ? void 0 : item.taskInst) === null || _a === void 0 ? void 0 : _a.earliestStartDate))
                                return undefined;
                            return moment(item.taskInst.earliestStartDate).format('YYYY-MM-DD');
                        },
                        fixable: true,
                        hiddenable: true,
                        hidden: Boolean(tableId === TableMyTaskId),
                        sortingKey: 'earliestStartDate',
                        width: 150,
                        readOnly,
                        inputProps: {
                            type: 'date',
                        },
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        Stats: (instance) => (_jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['earliestStartDate'] })),
                        actions: (instance, cell) => checkTaskPerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]),
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({
                                id: data.task,
                                earliestStartDate: moment(newValue, 'YYYY-MM-DD').toISOString(),
                            }),
                        },
                    }),
                    CellText({
                        id: 'latestEndDate',
                        Header: 'Latest end date',
                        accessor: (item) => {
                            var _a;
                            if (!((_a = item === null || item === void 0 ? void 0 : item.taskInst) === null || _a === void 0 ? void 0 : _a.latestEndDate))
                                return undefined;
                            return moment(item.taskInst.latestEndDate).format('YYYY-MM-DD');
                        },
                        fixable: true,
                        hiddenable: true,
                        hidden: Boolean(tableId === TableMyTaskId),
                        sortingKey: 'latestEndDate',
                        width: 150,
                        readOnly,
                        inputProps: {
                            type: 'date',
                        },
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        Stats: (instance) => _jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['latestEndDate'] }),
                        actions: (instance, cell) => checkTaskPerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]),
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({
                                id: data.task,
                                latestEndDate: moment(newValue, 'YYYY-MM-DD').toISOString(),
                            }),
                        },
                    }),
                    CellDuration({
                        Header: 'Assumption Estimation',
                        fixable: true,
                        hiddenable: true,
                        accessor: 'taskInst.quoteEstimLength',
                        id: 'quoteEstimLength',
                        actions: (instance, cell) => checkTaskPerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]),
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        width: 110,
                        readOnly: true,
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({ id: data.task, quoteEstimLength: newValue }),
                        },
                    }),
                    CellDuration({
                        Header: 'Supervisor Estimation',
                        fixable: true,
                        hiddenable: true,
                        accessor: 'taskInst.realEstimLength',
                        id: 'realEstimLength',
                        actions: (instance, cell) => checkTaskPerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]),
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        width: 110,
                        readOnly,
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTask(values)),
                            getData: (data, newValue) => ({ id: data.task, realEstimLength: newValue }),
                        },
                    }),
                    CellDuration({
                        id: 'takesEstimationSum',
                        Header: 'All takes estimation',
                        fixable: true,
                        hiddenable: true,
                        noFilterOption: true,
                        accessor: (item) => {
                            if (!item || !item.taskInst || !item.taskInst.takesInst)
                                return 0;
                            return reduce(item.taskInst.takesInst, (a, b) => a + b.estimLength, 0);
                        },
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        width: 110,
                        readOnly: true,
                        Stats: (instance) => _jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['estimLength'] }),
                        actions: (instance, cell) => checkTaskPerms([...defaultActions(cell.row)]),
                        save: {
                            resource: 'takes',
                        },
                    }),
                    CellProgression({
                        id: 'estimTaskTakesDifference',
                        Header: 'Task estimation / Takes estimations',
                        fixable: true,
                        hiddenable: true,
                        noFilterOption: true,
                        readMask: (value) => DurationRead({ value }),
                        accessor: (item) => {
                            if (!item || !item.taskInst || !item.taskInst.takesInst)
                                return 0;
                            const takesEstimationSum = reduce(item.taskInst.takesInst, (a, b) => a + b.estimLength, 0);
                            return {
                                value: takesEstimationSum,
                                max: item.taskInst.realEstimLength || 0,
                            };
                        },
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        width: 110,
                        readOnly: true,
                        Stats: (instance) => _jsx(CellStatsDefault, { instance: instance, resource: "tasks", fields: ['estimLength'] }),
                        actions: (instance, cell) => checkTaskPerms([...defaultActions(cell.row)]),
                        save: {
                            resource: 'takes',
                        },
                    }),
                    CellDuration({
                        Header: 'Spent time',
                        fixable: true,
                        hiddenable: true,
                        noFilterOption: true,
                        accessor: (item) => {
                            if (!item || !item.taskInst || !item.taskInst.activitiesInst)
                                return 0;
                            return reduce(item.taskInst.activitiesInst, (a, b) => a + b.duration, 0);
                        },
                        id: 'spentTime',
                        width: 100,
                        readOnly,
                        actions: (instance, cell) => checkTaskPerms([...defaultActions(cell.row)]),
                        Stats: (instance) => _jsx(CellStatsDefault, { instance: instance, resource: "activities" }),
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                    }),
                    CellProgression({
                        id: 'difference',
                        Header: 'Takes estimations / Spent time',
                        fixable: true,
                        hiddenable: true,
                        noFilterOption: true,
                        accessor: (item) => {
                            if (!item || !item.taskInst || !item.taskInst.activitiesInst || !item.taskInst.takesInst)
                                return 0;
                            const activities = reduce(item.taskInst.activitiesInst, (a, b) => a + b.duration, 0);
                            const estimLength = reduce(item.taskInst.takesInst, (a, b) => a + b.estimLength, 0);
                            return {
                                value: activities,
                                max: estimLength,
                            };
                        },
                        readMask: (value) => DurationRead({ value }),
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        width: 110,
                        readOnly,
                        actions: (instance, cell) => checkTaskPerms([...defaultActions(cell.row)]),
                        save: {
                            resource: 'takes',
                        },
                    }),
                ],
            },
            {
                Header: 'Takes',
                headerColor: vars.colors.yellowDark,
                id: 'take',
                columns: [
                    CellText({
                        id: 'takeNumber',
                        Header: 'Take number',
                        accessor: 'number',
                        fixable: true,
                        noFilterOption: true,
                        hiddenable: true,
                        width: 90,
                        readOnly,
                        actions: (instance, cell) => checkTakePerms([...defaultActions(cell.row)]),
                        Stats: (instance) => (_jsx(CellStatsDefault, { instance: instance, resource: "takes", fields: ['number__counts'], updateFields: (entry) => ({
                                'Take number': entry.fTakes__number,
                                count: entry.count,
                            }) })),
                    }),
                    CellStatus({
                        id: 'status',
                        Header: 'Status',
                        fixable: true,
                        hiddenable: true,
                        sortingKey: 'status__name',
                        accessor: 'status',
                        isHashed: (cell) => {
                            const lastTake = getLastTakeFromTask(cell.row.original.taskInst);
                            if (lastTake) {
                                const { number, statusInst = {} } = lastTake;
                                return number === 1 && statusInst.statusType === 0;
                            }
                            return false;
                        },
                        readOnly,
                        progressionStatus,
                        Stats: (instance) => (_jsx(CellStatsStatus, { instance: instance, progressionStatus: progressionStatus, columnName: "Status" })),
                        componentProps: {
                            disabledOptions: !permission(['my tasks___can close a take']) && tableId === TableMyTaskId
                                ? progressionStatusFilter(progressionStatus, 'with', ['new', 'close', 'retake']).map((ps) => ps.id)
                                : undefined,
                        },
                        actions: (instance, cell) => uniq(checkMyTasksPerms(cell.row, ['edit', 'copy', 'past'], progressionStatus, tableId, 'status').concat(checkTakePerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]))),
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTake(values)),
                            getData: (data, newValue) => ({ id: data.id, status: newValue, task: data.task }),
                        },
                    }),
                    CellDuration({
                        Header: 'Take estimation',
                        id: 'estimLength',
                        accessor: 'estimLength',
                        width: 120,
                        readOnly,
                        fixable: true,
                        hiddenable: true,
                        actions: (instance, cell) => checkTakePerms(['edit', 'copy', 'past', ...defaultActions(cell.row)]),
                        Stats: (instance) => _jsx(CellStatsDefault, { instance: instance, resource: "takes", fields: ['estimLength'] }),
                        searchFilterLabel: 'Last take estimation time',
                        filterLabel: 'Last take estimation time',
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTake(values)),
                            getData: (data, newValue) => ({ id: data.id, estimLength: newValue }),
                        },
                    }),
                    CellFlags({
                        Header: 'Flags',
                        id: 'flags',
                        // accessor: 'takeFlagsInst',
                        accessor: (item) => {
                            const { rel_takeFlagsInst, flagsInst } = item;
                            const accessor = rel_takeFlagsInst.map((el, index) => {
                                const flag = flagsInst.find((flag) => flag.id === el.flag);
                                const newItem = Object.assign(Object.assign({}, el[index]), { flagInst: Object.assign({}, flag) });
                                return newItem;
                            });
                            return accessor;
                        },
                        fixable: true,
                        hiddenable: true,
                        noFilterOption: true,
                        actions: (instance, cell) => {
                            if (cell.value !== undefined)
                                return checkTakePerms(['edit', 'delete', 'copy', 'past', ...defaultActions(cell.row)]);
                            return checkTakePerms([...defaultActions(cell.row)]);
                        },
                        foreignKey: 'take',
                        category: 'take',
                        getResourceID: (cell) => cell.row.original.id,
                        readOnly,
                        reduxSave: {
                            reduxAction: (values) => {
                                const { toCreate, toDelete } = values;
                                const promises = [
                                    toCreate.length && dispatch(createTakeFlags(toCreate)),
                                    toDelete.length && dispatch(deleteTakeFlags(toDelete)),
                                ];
                                return Promise.all(promises);
                            },
                            getData: (data, newValue, type) => {
                                if (type === 'delete') {
                                    return { toDelete: data.rel_takeFlagsInst, toCreate: [] };
                                }
                                const previousTakeFlags = data.rel_takeFlagsInst;
                                const newFlagsIds = newValue.map((takeFlag) => takeFlag.flag);
                                const previousFlagsIds = data.rel_takeFlagsInst.map((flag) => flag.flag);
                                const toDelete = previousTakeFlags.filter((takeFlag) => !newFlagsIds.includes(takeFlag.flag));
                                const toCreate = newValue
                                    .filter((takeFlag) => !previousFlagsIds.includes(takeFlag.flag))
                                    .map((takeFlag) => ({ flag: takeFlag.flag, take: takeFlag.take }));
                                return { toDelete, toCreate };
                            },
                        },
                    }),
                    CellRichText({
                        Header: 'Brief',
                        id: 'comment',
                        accessor: 'comment',
                        width: 200,
                        readOnly,
                        fixable: true,
                        noFilterOption: true,
                        hiddenable: true,
                        actions: (instance, cell) => uniq(checkMyTasksPerms(cell.row, ['edit', 'copy', 'past', 'delete'], progressionStatus, tableId, 'brief').concat(checkTakePerms(['edit', 'copy', 'past', 'delete', ...defaultActions(cell.row)]))),
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTake(values)),
                            getData: (data, newValue) => ({ id: data.id, comment: newValue }),
                        },
                    }),
                    CellRichText({
                        Header: 'Validation comment',
                        id: 'validationComment',
                        accessor: 'validationComment',
                        width: 200,
                        readOnly,
                        fixable: true,
                        hiddenable: true,
                        noFilterOption: true,
                        actions: (instance, cell) => uniq(checkMyTasksPerms(cell.row, ['edit', 'copy', 'past', 'delete'], progressionStatus, tableId, 'notes').concat(checkTakePerms(['edit', 'copy', 'past', 'delete', ...defaultActions(cell.row)]))),
                        reduxSave: {
                            bulk: true,
                            reduxAction: (values) => dispatch(updateTake(values)),
                            getData: (data, newValue) => ({ id: data.id, validationComment: newValue }),
                        },
                    }),
                    CellMedias({
                        Header: 'Refs',
                        id: 'takeRefMedias',
                        fixable: true,
                        hiddenable: true,
                        noFilterOption: true,
                        allowPinMedia: true,
                        allowValidateMedia: true,
                        accessor: (item) => {
                            if (!item)
                                return null;
                            const { takeRefMediasInst } = item;
                            return accessorTakeMedias(takeRefMediasInst);
                        },
                        actions: () => ['edit', 'delete'],
                        readOnly,
                        save: {
                            resource: 'takeRefMedias',
                            formatData: (item, value, cell, instance, type) => saveTakeMedias(value, 'takeRefMedias', item.id, item.takeRefMediasInst, type, cell.value),
                        },
                    }),
                    CellMedias({
                        Header: 'Validation medias',
                        id: 'takeValidationMedias',
                        fixable: true,
                        hiddenable: true,
                        noFilterOption: true,
                        allowPinMedia: true,
                        allowValidateMedia: true,
                        accessor: (item) => {
                            if (!item)
                                return null;
                            const { takeValidationMediasInst } = item;
                            return accessorTakeMedias(takeValidationMediasInst);
                        },
                        actions: (instance, cell) => uniq(checkMyTasksPerms(cell.row, ['edit', 'copy', 'past', 'delete'], progressionStatus, tableId, 'validationMedias').concat(checkTakePerms(['edit', 'copy', 'past', 'delete', ...defaultActions(cell.row)]))),
                        readOnly,
                        save: {
                            resource: 'takeValidationMedias',
                            formatData: (item, value, cell, instance, type) => saveTakeMedias(value, 'takeValidationMedias', item.id, item.takeValidationMediasInst, type, cell.value),
                        },
                    }),
                ],
            },
            {
                Header: 'Assets Attributes',
                id: 'assetAttributes',
                columns: attributesColumns || [],
                headerColor: vars.colors.red,
            },
            {
                Header: 'Spent Time',
                id: 'addTime',
                columns: [
                    CellLink({
                        Header: 'Add time',
                        id: 'addTime',
                        width: 80,
                        readOnly,
                        hidden: !showAddTime && !canAddActivityForOtherUsers && !canAddActivityOnAllTasks,
                        fixable: true,
                        fixed: 'right',
                        disabled: (cellInstance) => {
                            var _a, _b;
                            const { row } = cellInstance;
                            if (canAddActivityForOtherUsers)
                                return false;
                            if (canAddActivityOnAllTasks && !((_a = row.original.taskInst) === null || _a === void 0 ? void 0 : _a.assignedUser))
                                return false;
                            if (getUserId(row) === ((_b = row.original.taskInst) === null || _b === void 0 ? void 0 : _b.assignedUser))
                                return false;
                            if (tableId === 'MyTaskTableTask')
                                return false;
                            return true;
                        },
                        hiddenable: false,
                        actions: (cell) => checkTakePerms(defaultActions(cell.row)),
                        Pivoted: () => _jsx("div", { className: "flex center alignCenter fullHeight fullWidth grey", children: " - " }),
                        onClick: (row, event, instance) => {
                            const { updateCells } = instance;
                            const cell = row.cells[0];
                            openModal(_jsx(AddTimeModal, { addTimeToSpecifiedDate: addTimeToSpecifiedDate, showUserSelect: canAddActivityForOtherUsers, userId: getUserId(row), taskIds: [row.original.task], exponentTitle: row.original.name, onChange: (activities) => {
                                    if (cell)
                                        updateCells({ [cell.id]: cell });
                                    if (onAddActivities)
                                        onAddActivities(activities);
                                } }));
                        },
                        accessor: (row) => _jsx(FontIcon, { icon: "fas-hourglass-half" }),
                    }),
                ],
            },
        ];
    }, [progressionStatus, attributesColumns, requestsLoading, projects]);
    return (_jsx(TableRedux, { loading: requestsLoading || !attributesColumns, tableId: tableId, projectId: projectId, count: count, data: data, filters: filters, 
        // loadingProgress={progress}
        toggleButtons: ToggleButtons, extendToolbar: ExtendedToolbar, enableStats: enableStats, rowSelector: rowSelector, columns: columns, rowExpander: true, pivotBy: "task", statsOpen: statsOpen, setStatsOpen: setStatsOpen, stats: stats, statsIsLoading: statsIsLoading }));
}
